<template>
  <div class="sincereSellingPage">
    <NavigationBar :title="title"></NavigationBar>
    <div class="bodyContianer">
      <div class="cardItem1">
        <div class="cardItemTitle">
          <div class="cardItemLeft">
            <div class="line"></div>
            <div class="title">限时诚心卖保证金</div>
          </div>
          <div class="cardItemRight" @click="goSincereSellingCases">
            诚心卖服务案例
          </div>
        </div>
        <div class="cardItemBody">
          <div class="rightTopTag">活动结束后恢复原价¥30</div>
          <div class="price">¥5</div>
        </div>
      </div>
      <div class="cardItem2">
        <div class="cardItemHead">
          <div class="cardItemHeadTitle">
            <div class="line"></div>
            <div class="title">什么是诚心卖服务</div>
          </div>
          <div class="cardIteHeadBody">
            诚心卖是为每位卖家提供的免费服务，任何加入本服务的商品均会新增诚心卖标签并且享受到平台优先展示，同时商品进入诚心卖专区，若遇到市场价格波动，您也会收到相应推送。
          </div>
          <van-divider />
        </div>
        <div class="cardItem2Body">
          <div class="cardItem2BodyTitle">
            <div class="cardItemLeft">
              <div class="line"></div>
              <div class="title">加入诚心卖服务有什么好处</div>
            </div>
            <div class="cardItemRight" @click="goRefundDeposit">
              保证金退还规则
            </div>
          </div>
          <div class="cardItem2BodySwipeCard">
            <div class="swipeCardContainer">
              <div
                class="swipeCardItem"
                v-for="(item, index) in swipeItemList"
                :key="index"
              >
                <div class="swipeTitle">
                  {{ item.title }}
                </div>
                <img :src="item.img" alt="" class="swipeImg" />
                <div class="swipeContent">{{ item.content }}</div>
              </div>
              <div class="blankLine"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerBtn">
        <div class="submitBtn">立即支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationBar } from "@/components";
export default {
  name: "SincereSelling",
  data() {
    return {
      title: "诚心卖服务",
      swipeItemList: [
        {
          title: "商品优先展示",
          img: require("@/static/images/sincere_1@2x.png"),
          content:
            "您发布的商品将会在全平台优先展示，并且进入诚心卖专区，进一步提升曝光度",
        },
        {
          title: "新增诚心卖标签",
          img: require("@/static/images/sincere_2@2x.png"),
          content:
            "您的商标题前方将显示诚心卖标签，所有用户均可看到您已加入本计划和支付的保证金额",
        },
        {
          title: "诚心买专区曝光",
          img: require("@/static/images/sincere_3@2x.png"),
          content: "当您的商品上线后，会单独进入诚心买专区集中曝光，独享流量",
        },
        {
          title: "市场波动推送",
          img: require("@/static/images/sincere_4@2x.png"),
          content:
            "当您的商品上线后，当行情价格波动，您将会收到短信/推送，方便您掌握市场动向",
        },
      ],
    };
  },
  components: {
    NavigationBar,
  },
  methods: {
    goSincereSellingCases() {
      this.$router.push({
        name: "SincereSellingCases",
      });
    },
    goRefundDeposit() {
      this.$router.push({
        name: "RefundDeposit",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sincereSellingPage {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .bodyContianer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .cardItem1 {
      width: 345px;
      height: 108px;
      background: #ffffff;
      border-radius: 8px;
      padding: 16px 15px;
      box-sizing: border-box;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .cardItemTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cardItemLeft {
          display: flex;
          align-items: center;
          .line {
            width: 3px;
            height: 16px;
            background: #00a8ff;
            border-radius: 2px;
          }
          .title {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            margin-left: 11px;
          }
        }
        .cardItemRight {
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #00a8ff;
          line-height: 16px;
        }
      }
      .cardItemBody {
        width: 315px;
        height: 44px;
        background-image: url("~@/static/images/RectangleBg@2x.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        .rightTopTag {
          width: 122px;
          height: 16px;
          background: #f44444 linear-gradient(207deg, #ff7912 0%, #fe1c62 100%);
          border-radius: 4px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 12px;
          text-align: center;
          position: absolute;
          right: -1px;
          top: -2px;
        }
        .price {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fe1c62;
          line-height: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .cardItem2 {
      width: 345px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 12px;
      padding: 16px 0px 18px 0px;
      .cardItemHead {
        width: 100%;
        box-sizing: border-box;
        padding: 0px 15px 0px 15px;
        .cardItemHeadTitle {
          display: flex;
          align-items: center;
          .line {
            width: 3px;
            height: 16px;
            background: #00a8ff;
            border-radius: 2px;
          }
          .title {
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            margin-left: 12px;
          }
        }
        .cardIteHeadBody {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          line-height: 17px;
          margin-top: 8px;
        }
      }
      .cardItem2Body {
        overflow: hidden;
        .cardItem2BodyTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding: 0px 15px 0px 15px;
          .cardItemLeft {
            display: flex;
            align-items: center;
            .line {
              width: 3px;
              height: 16px;
              background: #00a8ff;
              border-radius: 2px;
            }
            .title {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #444444;
              line-height: 20px;
              margin-left: 11px;
            }
          }
          .cardItemRight {
            height: 16px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00a8ff;
            line-height: 16px;
          }
        }
        .cardItem2BodySwipeCard {
          height: 135px;
          margin-top: 12px;
          overflow-x: scroll;
          .swipeCardContainer {
            width: 100%;
            display: flex;
            align-items: center;
            .swipeCardItem {
              width: 280px;
              height: 135px;
              background: #f3fbff;
              margin-right: 10px;
              flex-shrink: 0;
              padding: 20px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .swipeTitle {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #444444;
                line-height: 17px;
              }
              .swipeImg {
                width: 20px;
                height: 20px;
              }
              .swipeContent {
                width: 240px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #777777;
                line-height: 17px;
              }
            }
            .blankLine {
              width: 1px;
              flex-shrink: 0;
              height: 135px;
            }
            .swipeCardItem:first-child {
              margin-left: 15px;
            }
            .swipeCardItem:last-child {
              margin-right: 15px;
            }
          }
        }
      }
    }
    .footerBtn {
      width: 375px;
      height: 88px;
      background: #ffffff;
      position: fixed;
      bottom: 0px;
      display: flex;
      justify-content: center;
      .submitBtn {
        width: 335px;
        height: 44px;
        background-image: url("~@/static/images/buttonBg@2x.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 16px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}
::v-deep .van-divider {
  margin: 12px 0px;
}
</style>
